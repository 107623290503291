import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import { useAuthJump } from '@/hooks/useAuthJump';
import { bannerList } from '@/stores/config';
import { globalMaxWidth } from '@/theme';
import { IBannerItem } from '@/types/config';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import ImageWithLoading from '../image';

interface Props {}

const HomeBannerContent = () => {
  const [mySwiper, setMySwiper] = useState<any>();
  const bannerListValue = useRecoilValue(bannerList);
  const { authJump } = useAuthJump();

  const width = Math.min(globalMaxWidth, window.innerWidth);

  useEffect(() => {
    if (!mySwiper) {
      return;
    }

    const interval = setInterval(() => {
      try {
        if (mySwiper?.isEnd) {
          mySwiper?.slideTo(0);
        } else {
          mySwiper?.slideNext();
        }
      } catch (error) {
        console.log(error);
      }
    }, 5000);
    return () => {
      interval && clearInterval(interval);
    };
  }, [mySwiper]);

  const handleClick = (data: IBannerItem) => {
    authJump(data);
  };

  return (
    <Box>
      <Swiper
        pagination={true}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
        // autoplay={{ delay: 5000 }}
        onSwiper={setMySwiper}
        loop
        breakpoints={{
          100: {
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            // autoplay: true,
          },
          // 600: {
          //   slidesPerView: 2,
          //   spaceBetween: 10,
          //   loop: true,
          //   // autoplay: true,
          // },
        }}
      >
        {bannerListValue.map((banner, index) => {
          return (
            <SwiperSlide key={`${banner.title}-${index}`}>
              <Box
                sx={{
                  width: width - 32,
                  height: (width - 32) / 2,
                }}
                onClick={() => handleClick(banner)}
              >
                <ImageWithLoading
                  // width="100%"
                  // height={height}
                  type="banner"
                  aspectRatio="2/1"
                  style={{
                    width: '100%',
                    maxWidth: globalMaxWidth - 32,
                    height: '100%',
                    borderRadius: '10px',
                    objectFit: 'fill',
                  }}
                  src={banner.pic}
                  alt={banner.title}
                />
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};

export default HomeBannerContent;
