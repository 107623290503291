import ICON_GIFT from '@/assets/icons/icon-gift.png';
import ICON_ARROW from '@/assets/icons/icon_return.svg';
import ICON_GIFT2 from '@/assets/icons/notice-gift.png';
import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import { Box, ListItemButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const RewardNotice = () => {
  const { user, isLogin } = useAuth();
  const navigate = useNavigate();
  const { __ } = useLang();

  if (!isLogin) {
    return null;
  }

  if (!user || !user?.unReceiveTimes) {
    return null;
  }

  return (
    <ListItemButton
      sx={{ p: 0, mb: 2, borderRadius: 0.5 }}
      onClick={() => {
        navigate('/receive-rewards');
      }}
    >
      <Box
        sx={{
          width: '100%',
          py: 1,
          px: 1.5,
          borderRadius: 0.5,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'primary.main',
          backdropFilter: 'blur(6px)',
          background:
            'linear-gradient(90deg, rgba(229, 140, 219, 0.38) 0.77%, rgba(229, 140, 219, 0.13) 99.23%), rgba(43, 45, 51, 0.75);',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src={ICON_GIFT}
            alt=""
            width={24}
            height={24}
            style={{ minWidth: 24 }}
          />
          <Box sx={{ flex: 'auto', minWidth: 0, ml: 0.5 }}>
            <Typography
              variant="body2"
              sx={{ fontStyle: 'italic', fontWeight: 500 }}
            >
              {__('Your reward has not been claimed yet！')}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                width: 26,
                minWidth: 26,
                height: 26,
                borderRadius: '50%',
                bgcolor: 'error.main',
                fontSize: 14,
                lineHeight: 1,
                fontWeight: 500,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 0.5,
              }}
            >
              {user.unReceiveTimes}
            </Box>
            <img src={ICON_ARROW} alt="" width={10} height={10} />
          </Box>
        </Box>
      </Box>
    </ListItemButton>
  );
};

export const RewardMaskNotice = () => {
  const { __ } = useLang();
  return (
    <Box
      sx={{
        backdropFilter: 'blur(1px)',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        background: 'rgba(26, 25, 25, 0.50)',
        // background:
        //   'linear-gradient(90deg, rgba(229, 140, 219, 0.38) 0.77%, rgba(229, 140, 219, 0.13) 99.23%), rgba(43, 45, 51, 0.75);',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Box
          sx={{
            width: 56,
            height: 56,
            borderRadius: '50%',
            overflow: 'hidden',
            bgcolor: 'divider',
            borderWidth: '3px',
            borderStyle: 'solid',
            borderColor: 'primary.main',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={ICON_GIFT2} alt="" width={38} height={38} />
        </Box>
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 700, mt: 1, lineHeight: '18px' }}
        >
          {__('Your reward has not been claimed yet！')}
        </Typography>
      </Box>
    </Box>
  );
};
